
import { defineComponent, ref, inject, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_CUSTOMER_USER } from '../graphql/Queries';
import { useRoute } from 'vue-router';
import { DELETE_END_USER } from '../graphql/Mutations';
import { useI18n } from 'vue-i18n';
import Table from '../../../../components/Table/Table.vue';
// import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
    components: {
        Table
        // InnerLoader
    },
    setup() {
        const store = useStore();
        const i18n = useI18n();
        const loader = ref(false);
        const pagination = ref({}) as Record<any, any>;
        const emitter: any = inject('emitter');
        const customers = ref([]) as Record<any, any>;
        const route = useRoute();
        const observable: any = ref();
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.EMAIL',
                key: 'email'
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.CREATED_AT',
                key: 'created_at'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const getCustomerUsers = (page = 1) => {
            loader.value = true;
            observable.value = Apollo.watchQuery({
                query: GET_CUSTOMER_USER,
                errorPolicy: 'all',
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    uuid: route.params.uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                customers.value = data.customer_users.data;
                pagination.value = data.customer_users;
                loader.value = false;
            });
        };

        getCustomerUsers(1);

        const handleCurrentChange = num => {
            getCustomerUsers(num);
        };

        const addEndUser = () => {
            emitter.emit('addEndUserUserAction', route.params.uuid);
        };

        const handleEdit = customer => {
            const formData = {
                customer: customer,
                uuid: route.params.uuid
            };
            customer = { ...customer, page: pagination.value?.current_page };
            emitter.emit('editEndUserUserAction', customer, route.params.uuid);
        };

        const handleDelete = id => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_END_USER,
                        variables: { customer_id: id },
                        update: (store, { data: { delete_end_user } }) => {
                            const pervious_record = store.readQuery({
                                query: GET_CUSTOMER_USER,
                                variables: {
                                    page: pagination.value.current_page,
                                    limit: 10,
                                    uuid: route.params.uuid
                                }
                            }) as Record<any, any>;

                            const data = pervious_record.customer_users.data.filter((t: any) => t.id !== delete_end_user);

                            store.writeQuery({
                                query: GET_CUSTOMER_USER,
                                data: {
                                    customer_users: {
                                        ...pervious_record.customer_users,
                                        data: [...data]
                                    }
                                },
                                variables: {
                                    page: pagination.value.current_page,
                                    limit: 10,
                                    uuid: route.params.uuid
                                }
                            });

                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(() => {
                    loader.value = false;
                });
        };

        emitter.on('endUserAdded', () => {
            getCustomerUsers(1);
        });

        onUnmounted(() => {
            observable.value?.unsubscribe();
        });
        return {
            customers,
            pagination,
            columns,
            loader,
            handleEdit,
            handleDelete,
            handleCurrentChange,
            addEndUser
        };
    }
});
