import { gql } from 'graphql-tag';
import { CUSTOMER_ADDRESSES_FIELDS, PAGINATION } from './Fragments';

export const GET_CUSTOMER_ADDRESSES = gql`
    query GetCustomerAddresses($page: Int, $limit: Int, $uuid: String! ) {
        customer_addresses(limit:$limit, page:$page, uuid:$uuid){
            ...Pagination
            data {
                ...CustomerAddressFields
            }
        }
    }
    ${CUSTOMER_ADDRESSES_FIELDS},
    ${PAGINATION}
`;

export const SEARCH_CUSTOMER_ADDRESSES = gql`
    query SearchCustomerAddresses($page: Int, $limit: Int, $search_key: String, $locale:String, $uuid:String) {
        search_customer_addresses(limit:$limit, page:$page, search_key:$search_key, locale:$locale, uuid:$uuid){
            ...Pagination
            data {
                ...CustomerAddressFields
            }
        }
    }
    ${CUSTOMER_ADDRESSES_FIELDS},
    ${PAGINATION}
`;

export default { GET_CUSTOMER_ADDRESSES, SEARCH_CUSTOMER_ADDRESSES };
